import { Authenticator } from '@aws-amplify/ui-react';
import React, { useState } from 'react';



function f1() {
    //function to make the text bold using DOM method
    document.getElementById("textarea1").style.fontStyle = "italic";
}

function TextEditor() {
    const [text, setText] = useState("");
  
    function handleChange(e) {
      setText(e.target.value);
    }

    function handleItalic() {
      document.getElementById("hmm").style.fontStyle = "italic";
    }
    function handleBold() {
      document.getElementById("hmm").style.fontWeight = "bold";
    }
    function noBold() {
      document.getElementById("hmm").style.fontWeight = "normal";
    }
  
    return (
      <div>
      <p>
        <button class ="authButton" onClick={handleItalic}>Italic</button> 
        <button class ="authButton" onClick={handleBold}>Bold</button> 
        <button class ="authButton" onClick={noBold}>noBold</button>
      </p>
        <textarea id='hmm' rows="20" cols="50" placeholder="Your text here"
          value={text}
          onChange={handleChange}
        />
        <textarea rows="20" cols="50" placeholder="Typed Text" 
        value={text}/>
        <p class ="timeColor">{text}</p>
      </div>
    );
  }

const Editor = () => {

    return(
        
        <Authenticator>
           {({ signOut, user }) => (
               //anything in this div counts
               <div> 
                   <h2 class ="timeColor" >Logged in as: {user.attributes.email} </h2>
                   <h2 class ="timeColor">Authenticated Editor</h2>
                   <br></br><br></br><br></br>

                   <button class="authButton" onclick={f1}> Bold </button>

                    <br></br> <br></br>
                    <TextEditor />
            
                   <br></br><br></br>
                  
                   <button class="authButton" onClick={signOut}>Sign Out</button> 


                 {/*  <a href="./">Back to ppremium</a>*/}
               </div> 
           )}
       
        </Authenticator>
       );


};

export default (Editor);