

const About = () => {

    return (

        <div >
           
            <h1 class="timeColor">This is the about page</h1>

            <br></br>

            
            <a class="z" href="https://www.w3schools.com" target="_blank" rel="noopener noreferrer">Visit W3Schools</a>
            <br></br>
          
            <a class="z" href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJs</a>
            <br></br>

            
            <a class="z" href="https://reactrouter.com/en/main" target="_blank" rel="noopener noreferrer">react-router-dom</a>
            <br></br>
            <a class="z" href="https://docs.amplify.aws/cli/start/install/" target="_blank" rel="noopener noreferrer">AWC CLI</a>
            <br></br>
            <a class="z" href="https://docs.amplify.aws/ui/q/framework/react/" target="_blank" rel="noopener noreferrer">Amplify UI</a>
            <br></br>
            <a class="z" href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer">Node JS</a>
            <br></br>
            <a class="z" href="https://docs.npmjs.com/cli/v6/commands/npm" target="_blank" rel="noopener noreferrer">NPM</a>
            <br></br>
          


        </div>

    )



};

export default About;