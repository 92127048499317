import { Authenticator } from '@aws-amplify/ui-react';
import { NavLink } from 'react-router-dom';
import React from 'react';

  const PremiumPage = () => {
    return(  
     <Authenticator>
        {({ signOut, user }) => (
            //anything in this div counts
            <div> 
                
               <h2 class ="timeColor" >Logged in as: {user.attributes.email} </h2>
                <h2 class ="timeColor">Authenticated</h2>
                <br></br>
                
                <div className="content">
                <NavLink className="content" activeClassName="active" to="/premium/editor">Editor</NavLink>{/*link to editor */}
                <br></br> <br></br>
                </div>
                <button class="authButton" onClick={signOut}>Sign Out</button> 
           {/*   <p><a href="./premium/editor">Visit W3Schools.com!</a></p>*/}
            </div> //anything after here wont count ;)
        )}
     </Authenticator>
    );
    };

   
  





export default PremiumPage;



 