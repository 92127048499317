const Home = () => {

    return (
        
    <div>
        
        <h1 class="timeColor">Home</h1>
    </div>
    
    
    );


};

export default Home;