import React from 'react';
import ReactDOM from 'react-dom/client';


import App from './App';
import reportWebVitals from './reportWebVitals';

import '@aws-amplify/ui-react/styles.css';
import './index.css'; //place after aws styles.css to override certain aws styles w/ custom from index.css overiding amplify components

import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import {AmplifyProvider} from '@aws-amplify/ui-react'; //allows us to edit the login UI (need to look more into this) need to see if this was prior to/or not of changing or importing the css style before the amplify one's


Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));

//im not sure if this part down here is necesarry
const theme = {
  name:'pretty',
  tokens:{
        colors:{
          font:{
            primary:{ value: '#FF9900'}, },
             
        background:{ primary:{value: 'transparent'}, }
    }
  
  }
  
  }

/////////////////////////////////////////////
//
//  Functions
//
/////////////////////////////////////////////
/*
function Clock(props) {
  return (
    <div>
      <h1>Hello, world!</h1>
      <h2>It is {props.date.toLocaleTimeString()}.</h2>
    </div>
  );
}

function tick() {
  root.render(<Clock date={new Date()} />);
}*/

/*
function lonely() {
  const element2 = (
    <div>
      <h2>It is {new Date().toLocaleTimeString()}.</h2>
    </div>
  );
  root.render(element2);
}*/

function rerender() {
  const element = (
    <div>
      <AmplifyProvider theme ={theme} >
          <App />
         
          
      </AmplifyProvider>  
      {/* 
        <React.StrictMode>
          <App />
        </React.StrictMode>
      */}
      {/* this actually renders the links and website*/}
      {/* anything inside this function is diaplyed global outside of <App /> ...ex .. <h2>It is {new Date().toLocaleTimeString()}.</h2> */}      
    </div>
  );
  root.render(element);
}
///////////////////////////////////////////////////////////////////////////////////
//
//  Actual function calls
//
///////////////////////////////////////////////////////////////////////////////////
//rerender();  //calls the render and keep the state the same w/o rerendering
//setInterval(tick, 1000); //set this iff at flickergin stopped rendering this function on my apple phone safari
setInterval(rerender, 1000);  //can rerender any function 1 time per second
// --------- original render -------------
  //root.render(
  //<React.StrictMode>
  //<App />
  //</React.StrictMode>);


/////////////////////////////////////////////////////////////////////////////////////////
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
