
//import './index.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import PremiumPage from './pages/PremiumPage';
import Editor from './pages/editor';

function App() {
  return (
    <div className="App">
      <Router>
        <div className="content">
          <NavLink className="content" exact activeClassName="active" to="/">Home</NavLink>
          <NavLink className="content" activeClassName="active" to="/about">About</NavLink>
          <NavLink className="content" activeClassName="active" to="/premium">Login</NavLink>
          <NavLink className="contentTime">{new Date().toLocaleTimeString()} CT</NavLink>
          
          <hr></hr>
        </div>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/premium" element={<PremiumPage />}></Route>
          <Route path="/premium/editor" element={<Editor />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
